import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";

export const actionUpdateTicket =
  (userData, navigate, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_TICKET_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/tickets/${id}`, userData);
      dispatch({ type: actionTypes.UPDATE_TICKET_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.UPDATE_TICKET_DATA,
          payload: result.data.data,
        });

        message.success("tickets Updated Successfully", 5);

        navigate && setTimeout(() => navigate(`/ticket-view`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_TICKET_LOADING, payload: false });
    }
  };

export const actionGetTickets =
  (
    offset,
    limit,
    keyword,
    statusList,
    rate_issue,
    sr_severity,
    by_ticket_age,
    start_date,
    end_date,
    level
  ) =>
    async (dispatch, getState) => {
      dispatch({ type: actionTypes.GET_ALL_TICKET_LOADING, payload: true });
      try {
        let result = await axios.get(
          `${BASE_URL}/tickets?limit=${limit}&offset=${offset}${keyword ? "&keyword=" + keyword : ""
          }${statusList ? "&statusList=" + statusList : ""}${rate_issue ? "&rate_issue=" + rate_issue : ""
          }${sr_severity ? "&sr_severity=" + sr_severity : ""}${by_ticket_age ? "&by_ticket_age=" + by_ticket_age : ""
          }${start_date ? "&start_date=" + start_date : ""}${end_date ? "&end_date=" + end_date : ""
          }${level ? "&level=" + level : ""}`
        );
        dispatch({ type: actionTypes.GET_ALL_TICKET_LOADING, payload: false });
        if (parseInt(result.data.status) === 200) {
          dispatch({
            type: actionTypes.GET_ALL_TICKET_DATA,
            payload: result.data.data,
          });
          dispatch({
            type: actionTypes.TOTAL_TICKETS_COUNT,
            payload: result.data.total_records,
          });
        } else if (parseInt(result.data.status) === 404) {
          dispatch({
            type: actionTypes.GET_ALL_TICKET_DATA,
            payload: [],
          });
          dispatch({
            type: actionTypes.TOTAL_TICKETS_COUNT,
            payload: 0,
          });
        } else message.error(result.data.error, 5);
      } catch (error) {
        dispatch({ type: actionTypes.GET_ALL_TICKET_LOADING, payload: false });
      }
    };

export const actionGetAllTickets =
  (
    offset,
    limit,
    keyword,
    statusList,
    rate_issue,
    sr_severity,
    start_date,
    end_date,
    level
    // getall
  ) =>
    async (dispatch, getState) => {
      dispatch({
        type: actionTypes.GET_ALL_EXPORT_TICKET_LOADING,
        payload: true,
      });
      try {
        let result = await axios.get(
          `${BASE_URL}/tickets?limit=${limit}&offset=${offset}${keyword ? "&keyword=" + keyword : ""
          }${statusList ? "&statusList=" + statusList : ""}${rate_issue ? "&rate_issue=" + rate_issue : ""
          }${sr_severity ? "&sr_severity=" + sr_severity : ""}${"getall" ? "&getall=" + "YES" : ""
          }${start_date ? "&start_date=" + start_date : ""}${end_date ? "&end_date=" + end_date : ""
          }${level ? "&level=" + level : ""}`
        );
        dispatch({
          type: actionTypes.GET_ALL_EXPORT_TICKET_LOADING,
          payload: false,
        });
        if (parseInt(result.data.status) === 200) {
          dispatch({
            type: actionTypes.GET_ALL_EXPORT_TICKET_DATA,
            payload: result.data.data,
          });
        } else if (parseInt(result.data.status) === 404) {
          dispatch({
            type: actionTypes.GET_ALL_EXPORT_TICKET_DATA,
            payload: [],
          });
        } else message.error(result.data.message, 5);
      } catch (error) {
        dispatch({
          type: actionTypes.GET_ALL_EXPORT_TICKET_LOADING,
          payload: false,
        });
      }
    };

export const actionDeleteTicket = (id) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.DELETE_TICKET_LOADING,
    payload: true,
  });
  try {
    let result = await axios.delete(`${BASE_URL}/tickets/${id}`);
    dispatch({
      type: actionTypes.DELETE_TICKET_LOADING,
      payload: false,
    });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.DELETE_TICKET_ID, payload: id });
      message.success(result.data.message, 5);
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({
      type: actionTypes.DELETE_TICKET_LOADING,
      payload: false,
    });
  }
};

export const actionAddTicket =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_TICKET_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/tickets`, userData);
      dispatch({ type: actionTypes.ADD_TICKET_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_TICKET_DATA,
          payload: result.data.data,
        });
        message.success("tickets Added Successfully", 5);
        navigate && setTimeout(() => navigate(`/tickets`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_TICKET_LOADING, payload: false });
    }
  };

// export const actionUpdateTicket =
//   (userData, navigate) => async (dispatch, getState) => {
//     dispatch({ type: actionTypes.UPDATE_TICKET_LOADING, payload: true });
//     try {
//       let result = await axios.post(`${BASE_URL}/tickets`, userData);
//       dispatch({ type: actionTypes.UPDATE_TICKET_LOADING, payload: false });
//       if (parseInt(result.data.status) === 200) {
//         dispatch({
//           type: actionTypes.UPDATE_TICKET_DATA,
//           payload: result.data.data,
//         });
//         message.success("tickets UPDATEed Successfully", 5);
//         navigate && setTimeout(() => navigate(`/tickets`), 1000);
//       } else {
//         message.error(result.data.message, 5);
//       }
//     } catch (error) {
//       message.error(error.message, 5);
//       dispatch({ type: actionTypes.UPDATE_TICKET_LOADING, payload: false });
//     }
//   };

export const actionSendMail =
  (id, data, setIsModalOpen, setEmails) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SEND_MAIL_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/tickets/sendmail/${id}`, {
        emails: data,
      });
      dispatch({ type: actionTypes.SEND_MAIL_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.SEND_EMAIL_DATA,
          payload: result.data.data,
        });
        message.success("Send Successfully", 5);
        setIsModalOpen && setIsModalOpen(false);
        setEmails && setEmails([]);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error("Each value in email must be an Email");
      dispatch({ type: actionTypes.SEND_MAIL_LOADING, payload: false });
    }
  };

export const actionGetTicketDetail = (
  ticketId,
  keyword,
  paramValue,
  rateIssue,
  srSeverity,
  levelParams,
  sDate,
  eDate
) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_TICKET_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/tickets/${ticketId}?getall=YES${keyword ? "&keyword=" + keyword : ""
      }${paramValue ? "&statusList=" + paramValue : ""}${rateIssue ? "&rate_issue=" + rateIssue : ""
      }${srSeverity ? "&sr_severity=" + srSeverity : ""}${sDate ? "&start_date=" + sDate : ""}${eDate ? "&end_date=" + eDate : ""
      }${levelParams ? "&level=" + levelParams : ""}`);
    dispatch({ type: actionTypes.GET_TICKET_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_TICKET_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_TICKET_DETAIL_LOADING, payload: false });
  }
};

// LOG API

export const actionGetTicketLog = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_TICKET_LOG_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/ticket-logs/${id}`);
    dispatch({
      type: actionTypes.GET_TICKET_LOG_LOADING,
      payload: false,
    });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_TICKET_LOG_DATA,
        payload: result.data.data.logs,
      });
      dispatch({
        type: actionTypes.GET_SERVICE_NUMBER,
        payload: result.data.data.service_request_number,
      });
    } else if (parseInt(result.data.status) === 404) {
      dispatch({
        type: actionTypes.GET_TICKET_LOG_DATA,
        payload: [],
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({
      type: actionTypes.GET_TICKET_LOG_LOADING,
      payload: false,
    });
  }
};

export const actionGetAllTicketLog =
  (
    keyword,
    statusList,
    rate_issue,
    sr_severity,
    start_date,
    end_date,
    level,
  ) =>
    async (dispatch, getState) => {
      dispatch({ type: actionTypes.GET_ALL_TICKET_LOG_LOADING, payload: true });
      try {
        let result = await axios.get(
          `${BASE_URL}/ticket-logs?${keyword ? "&keyword=" + keyword : ""}${statusList ? "&statusList=" + statusList : ""
          }${rate_issue ? "&rate_issue=" + rate_issue : ""}${sr_severity ? "&sr_severity=" + sr_severity : ""
          }${"getall" ? "&getall=" + "YES" : ""}${start_date ? "&start_date=" + start_date : ""
          }${end_date ? "&end_date=" + end_date : ""}${level ? "&level=" + level : ""}
        `
          /* ${description ? "&description=" + description : ""} */
        );
        dispatch({
          type: actionTypes.GET_ALL_TICKET_LOG_LOADING,
          payload: false,
        });
        if (parseInt(result.data.status) === 200) {
          dispatch({
            type: actionTypes.GET_ALL_TICKET_LOG_DATA,
            payload: result.data.data,
          });
        } else if (parseInt(result.data.status) === 404) {
          dispatch({
            type: actionTypes.GET_ALL_TICKET_LOG_DATA,
            payload: [],
          });
        } else message.error(result.data.message, 5);
      } catch (error) {
        dispatch({
          type: actionTypes.GET_ALL_TICKET_LOG_LOADING,
          payload: false,
        });
      }
    };

export const actionUpdateTicketStatus =
  (id, userData) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_TICKET_STATUS_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/tickets/update-status/${id}`, {
        status: userData,
      });
      dispatch({
        type: actionTypes.UPDATE_TICKET_STATUS_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.UPDATE_TICKET_STATUS_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.UPDATE_TICKET_DETAIL_STATUS_DATA,
          payload: result.data.data,
        });

        dispatch({
          type: actionTypes.UPDATE_TIME_DATA,
          payload: result.data.data,
        });

        dispatch({
          type: actionTypes.UPDATE_DETAIL_TIME_DATA,
          payload: result.data.data,
        });

        message.success(result.data.message, 5);
        // navigate && setTimeout(() => navigate(`/tickets`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.UPDATE_TICKET_STATUS_LOADING,
        payload: false,
      });
    }
  };

export const actionUpdatePriorityStatus =
  (id, userData) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.UPDATE_PRIORITY_STATUS_LOADING,
      payload: true,
    });
    try {
      let result = await axios.post(
        `${BASE_URL}/tickets/update-priroty/${id}`,
        {
          rate_issuue: userData,
        }
      );
      dispatch({
        type: actionTypes.UPDATE_PRIORITY_STATUS_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.UPDATE_PRIORITY_STATUS_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.UPDATE_PRIORITY_DETAIL_STATUS_DATA,
          payload: result.data.data,
        });

        dispatch({
          type: actionTypes.UPDATE_TIME_DATA,
          payload: result.data.data,
        });

        dispatch({
          type: actionTypes.UPDATE_DETAIL_TIME_DATA,
          payload: result.data.data,
        });

        message.success(result.data.message, 5);
        // navigate && setTimeout(() => navigate(`/tickets`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.UPDATE_PRIORITY_STATUS_LOADING,
        payload: false,
      });
    }
  };

export const actionUpdateLevelStatus =
  (id, userData) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.UPDATE_TICKET_LEVEL_LOADING,
      payload: true,
    });
    try {
      let result = await axios.post(`${BASE_URL}/tickets/update-level/${id}`, {
        level: userData,
      });
      dispatch({
        type: actionTypes.UPDATE_TICKET_LEVEL_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.UPDATE_TICKET_LEVEL_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.UPDATE_TICKET_DETAIL_LEVEL_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.UPDATE_TIME_DATA,
          payload: result.data.data,
        });

        dispatch({
          type: actionTypes.UPDATE_DETAIL_TIME_DATA,
          payload: result.data.data,
        });
        message.success(result.data.message, 5);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.UPDATE_TICKET_LEVEL_LOADING,
        payload: false,
      });
    }
  };

export const actionUpdateTicketSeverity =
  (id, userData) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_SEVERITY_LOADING, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/tickets/update-sr-severity/${id}`,
        {
          sr_severity: userData,
        }
      );
      dispatch({
        type: actionTypes.UPDATE_SEVERITY_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.UPDATE_SEVERITY_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.UPDATE_SEVERITY_DETAIL_DATA,
          payload: result.data.data,
        });

        dispatch({
          type: actionTypes.UPDATE_TIME_DATA,
          payload: result.data.data,
        });

        dispatch({
          type: actionTypes.UPDATE_DETAIL_TIME_DATA,
          payload: result.data.data,
        });

        message.success(result.data.message, 5);
        // navigate && setTimeout(() => navigate(`/tickets`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.UPDATE_SEVERITY_LOADING,
        payload: false,
      });
    }
  };

export const actionGetDashboardData =
  (start_date, end_date) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_DASHBOARD_LOADING, payload: true });
    let queryString = "";

    if (start_date) {
      queryString += `start_date=${start_date}&`;
    }
    if (end_date) {
      queryString += `end_date=${end_date}`;
    }

    try {
      let result = await axios.get(
        `${BASE_URL}/tickets/dashboard${queryString ? "?" + queryString : ""}`
      );
      dispatch({ type: actionTypes.GET_DASHBOARD_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_DASHBOARD_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.REMOVE_DATA,
          payload: result.data.data,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      dispatch({ type: actionTypes.GET_DASHBOARD_LOADING, payload: false });
    }
  };

export const actionRemoveTaskDashboard =
  (status, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.REMOVE_TASK_STATUS_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/tasks/status/${id}`, {
        status,
      });
      dispatch({
        type: actionTypes.REMOVE_TASK_STATUS_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.REMOVE_TASK_STATUS_DATA,
          payload: result.data.data,
        });

        //message.success("Task Status Updated Successfully", 5);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.REMOVE_TASK_STATUS_LOADING,
        payload: false,
      });
    }
  };

export const actionGetAreaOfConcern = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_ALL_AREA_CONCERN_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/area-of-concern`);
    dispatch({
      type: actionTypes.GET_ALL_AREA_CONCERN_LOADING,
      payload: false,
    });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_ALL_AREA_CONCERN_DATA,
        payload: result.data.data,
      });
    } else if (parseInt(result.data.status) === 404) {
      dispatch({
        type: actionTypes.GET_ALL_AREA_CONCERN_DATA,
        payload: [],
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ALL_AREA_CONCERN_LOADING,
      payload: false,
    });
  }
};

export const actionAddAreaOfConcern =
  (userData) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_AREA_CONCERN_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/area-of-concern`, userData);
      dispatch({
        type: actionTypes.ADD_AREA_CONCERN_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_AREA_CONCERN_DATA,
          payload: result.data.data,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      dispatch({
        type: actionTypes.ADD_AREA_CONCERN_LOADING,
        payload: false,
      });
    }
  };

// Resolution

export const actionAddResolution =
  (userData, navigate, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_RESOLVE_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/tickets/resolve/${id}`, {
        resolve_note: userData,
      });
      dispatch({ type: actionTypes.ADD_RESOLVE_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_RESOLVE_DATA,
          payload: result.data.data,
        });
        message.success(result.data.message, 5);
        //form && form.resetFields();
        navigate && setTimeout(() => navigate(`/tickets`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_RESOLVE_LOADING, payload: false });
    }
  };

export const storeClickedData = (data, navigate) => (dispatch) => {
  dispatch({
    type: actionTypes.STORE_CLICKED_DATA,
    payload: data,
  });

  navigate && navigate(`/ticket-view`);
};

export const actionPage = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PAGE,
    payload: data,
  });

  // navigate && navigate(`/ticket-view`);
};

// Task Crud For Tasks

export const actionGetAllTask = (id) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_TASK_LOADING, payload: true });
  try {
    let result = await axios.get(
      `${BASE_URL}/tasks/${id}?limit=20&offset=0&getall=YES`
    );
    dispatch({ type: actionTypes.GET_TASK_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_TASK_DATA,
        payload: result.data.data,
      });
      dispatch({
        type: actionTypes.TASK_COUNT,
        payload: result.data.total_records,
      });
    } else if (parseInt(result.data.status) === 404) {
      dispatch({
        type: actionTypes.GET_TASK_DATA,
        payload: [],
      });
    } else {
      message.error(result.data.message, 5);
    }
  } catch (error) {
    message.error(error, 5);
    dispatch({
      type: actionTypes.GET_TASK_LOADING,
      payload: false,
    });
  }
};

export const actionCreateTask =
  (userData, form, setTaskValue) => async (dispatch) => {
    dispatch({ type: actionTypes.CREATE_TASK_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/tasks`, userData);
      dispatch({ type: actionTypes.CREATE_TASK_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.CREATE_TASK_DATA,
          payload: result.data.data,
        });

        message.success("Task Added Successfully", 5);
        form && form.resetFields();
        setTaskValue && setTaskValue("");
        //navigate && setTimeout(() => navigate(`/tickets`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error, 5);
      dispatch({ type: actionTypes.CREATE_TASK_LOADING, payload: false });
    }
  };

export const actionGetTimingData =
  (userData, form, setTaskValue) => async (dispatch) => {
    dispatch({ type: actionTypes.CREATE_TASK_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/tasks`, userData);
      dispatch({ type: actionTypes.CREATE_TASK_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.CREATE_TASK_DATA,
          payload: result.data.data,
        });

        message.success("Task Added Successfully", 5);
        form && form.resetFields();
        setTaskValue && setTaskValue("");
        //navigate && setTimeout(() => navigate(`/tickets`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error, 5);
      dispatch({ type: actionTypes.CREATE_TASK_LOADING, payload: false });
    }
  };

export const actionUpdateTask =
  (userData, navigate, id) => async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_TASK_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/create-task`, userData);
      dispatch({ type: actionTypes.UPDATE_TASK_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/tickets`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error, 5);
      dispatch({ type: actionTypes.UPDATE_TASK_LOADING, payload: false });
    }
  };

export const actionUpdateTaskTime =
  (userData, id, setEstimatedHours) => async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_TASK_TIME_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/tasks/time/${id}`, {
        estimated_time: userData,
      });
      dispatch({ type: actionTypes.UPDATE_TASK_TIME_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.TASK_TIME,
          payload: result.data.data,
        });
        message.success(result.data.message, 5);
        setEstimatedHours && setEstimatedHours([]);
        // seyt
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error, 5);
      dispatch({ type: actionTypes.UPDATE_TASK_TIME_LOADING, payload: false });
    }
  };

export const actionUpdateTaskUser =
  (userData, id, setSelectedValues) => async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_TASK_USER_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/tasks/agent/${id}`, {
        agent: userData,
      });
      dispatch({ type: actionTypes.UPDATE_TASK_USER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        //navigate && setTimeout(() => navigate(`/tickets`), 1000);
        setSelectedValues && setSelectedValues([]);
        dispatch({
          type: actionTypes.TASK_USER,
          payload: result.data.data,
        });

      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error, 5);
      dispatch({ type: actionTypes.UPDATE_TASK_USER_LOADING, payload: false });
    }
  };

export const actionUpdateTaskName = (userData, id) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_TASK_NAME_LOADING, payload: true });
  try {
    let result = await axios.put(`${BASE_URL}/tasks/name/${id}`, {
      task_name: userData,
    });
    dispatch({ type: actionTypes.UPDATE_TASK_NAME_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      message.success(result.data.message, 5);
      dispatch({
        type: actionTypes.TASK_NAME,
        payload: result.data.data,
      });
    } else {
      message.error(result.data.message, 5);
    }
  } catch (error) {
    message.error(error, 5);
    dispatch({ type: actionTypes.UPDATE_TASK_NAME_LOADING, payload: false });
  }
};

export const deleteTask = (id) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_TASK_LOADING, payload: true });
  try {
    let result = await axios.delete(`${BASE_URL}/tasks/${id}`);
    dispatch({ type: actionTypes.DELETE_TASK_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.DELETE_TASK_ID, payload: id });

      message.success("Task Deleted Successfully", 5);
    } else message.error(result.data.error, 5);
  } catch (error) {
    message.error(error, 5);
    dispatch({ type: actionTypes.DELETE_TASK_LOADING, payload: false });
  }
};

// for task:
// export const SORT_TASKS = 'SORT_TASKS';
export const actionSortTasks = (sortedData) => (dispatch) => {
  dispatch({ type: actionTypes.SORT_TASKS, payload: sortedData });
};
export const actionCreateTimingResolution =
  (userData, setIsModalOpen, setTicket) => async (dispatch) => {
    // (userData) => async (dispatch) => {
    dispatch({ type: actionTypes.CREATE_TIMING_LOADING, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/tickets-resolution-timing`,
        userData
      );
      // ticket: ticket,);
      dispatch({ type: actionTypes.CREATE_TIMING_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.CREATE_TIMING_DATA,
          payload: result.data.data,
        });

        // message.success("Task Added Successfully", 5);
        setIsModalOpen(false) && setTicket("");
        //form.resetFields();
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error, 5);
    }
  };

export const actionGetAllTimingResolution = (id) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_TIMING_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/tickets-resolution-timing/${id}`);
    dispatch({ type: actionTypes.GET_TIMING_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_TIMING_DATA,
        payload: result.data.data,
      });
      dispatch({
        type: actionTypes.TIME_COUNT,
        payload: result.data.total_records,
      });
    } else if (parseInt(result.data.status) === 404) {
      dispatch({
        type: actionTypes.GET_TIMING_DATA,
        payload: [],
      });
    } else {
      message.error(result.data.message, 5);
    }
  } catch (error) {
    message.error(error, 5);
    dispatch({
      type: actionTypes.GET_TIMING_LOADING,
      payload: false,
    });
  }
};

export const actionUpdateTaskStatus =
  (status, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_TASKSTATUS_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/tasks/status/${id}`, {
        status,
      });

      dispatch({ type: actionTypes.UPDATE_TASKSTATUS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.UPDATE_TASK_STATUS,
          payload: result.data.data,
        });

        dispatch({
          type: actionTypes.REMOVE_TASK_STATUS,
          payload: id,
        });

        message.success("Task Status Updated Successfully", 5);
      } else {

        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);

      dispatch({ type: actionTypes.UPDATE_TASKSTATUS_LOADING, payload: false });
    }
  };

export const actionTaskSorting =
  (payload, ticketId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.TASK_SORTING_LOADING, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/tasks/sort-order/${ticketId}`,
        payload
      );
      dispatch({ type: actionTypes.TASK_SORTING_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        // dispatch({
        //   type: actionTypes.TASK_SORTING_DATA,
        //   payload: result.data.data,
        // });
        // message.success("Task Upadted Sucessfully", 5);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.TASK_SORTING_LOADING, payload: false });
    }
  };
