import { Button, Card, Col, Form, Input, Row, Table, Select, Spin, TimePicker, Space, Tooltip, Popconfirm, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from "dayjs";
import moment from "moment/moment";
import {
    DeleteOutlined,
    EditOutlined
} from "@ant-design/icons";
import { actionCreateTimingResolution, actionGetAllTimingResolution } from '../../store/actions/ticketAction';
import { actionAddTime, actionDeleteTime, actionGetTimeAll, actionGetTimeDetail, actionUpdateTime } from '../../store/actions/timeAction';
import { connect } from 'react-redux';
import { actionGetUsersAllData } from '../../store/actions/userAction';
import { useParams } from 'react-router-dom';
const { RangePicker } = DatePicker;
const ResolutionTime = (props) => {
    const {
        actionGetAllTimingResolution,
        actionCreateTimingResolution,
        actionGetUsersAllData,
        actionGetTimeAll,
        getTimedLoader,
        userProfileData,
        addTimeLoader,
        timeData,
        timeCount,
        allUsersData,
        actionDeleteTime,
        actionUpdateTime,
        actionAddTime,
        actionGetTimeDetail
    } = props;
    const [form2] = Form.useForm();
    const [Hours, setHours] = useState("");
    const { ticketId } = useParams();
    const [TimeStartDate, setTimeStartDate] = useState("");
    const [TimeEndDate, setTimeEndDate] = useState("");
    const [timeDetailUpdate, setTimeDetailUpdate] = useState(false);
    const [timeDetailDataId, setTimeDetailDataId] = useState("");

    useEffect(() => {
        actionGetUsersAllData(0, 10);
        actionGetTimeAll(parseFloat(ticketId));
        if (userProfileData && userProfileData.roles === "AGENT") {
            form2.setFieldsValue({
              agent: userProfileData.id,
            });
          }
    }, [ticketId])

    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const agentData = [];
    allUsersData?.map((data, index) => {
        if (data.roles === "AGENT") {
            agentData.push({
                key: index,
                label: data.first_name + " " + data.last_name,
                value: data.id,
            });
        }
    });
    const [timeDifferences, setTimeDifferences] = useState([]);

    const totalHours = (value, e) => {
        var startTime = moment(e[0], "HH:mm");
        var endTime = moment(e[1], "HH:mm");
        var duration = moment.duration(endTime.diff(startTime));
        var hours = parseInt(duration.asHours().toString());
        var minutes = parseInt(duration.asMinutes().toString()) % 60;
        setHours(`${hours} hrs ${minutes} min`);
    };

    const handleFormSubmit = async () => {
        try {
            // Validate and submit the form data here
            await form2.validateFields();
            form2.submit();
        } catch (error) {
            console.error("Form validation failed:", error);
        }
    };
    // Function to calculate time difference

    const onFinishTiming = (values) => {
        console.log({ values });
        const request =
            values &&
            values.items.map((item) => {
                const agent = agentData.filter((d) => d.value === item.agent);

                return {
                    agent: item.agent,
                    date: dayjs(item.date).format("YYYY-MM-DD"),
                    times: item?.dates?.map((data) => ({
                        start_time: dayjs(data.times[0].$d).format("HH:mm"),
                        end_time: dayjs(data.times[1].$d).format("HH:mm"),
                    })),
                };
            });

        const d = {
            ticket: parseInt(ticketId),
            data: request,
        };

        actionCreateTimingResolution(d);
    };

    const handleChange = (time, timeString, c) => {
        setTimeStartDate(timeString[0]);
        setTimeEndDate(timeString[1]);
    };

    const confirm = (timeData, id) => {
        actionDeleteTime(timeData, id);
    };

    const handleSetTimeData = (id) => {
        //actionGetTimeDetail(id);
        const timeDetailDataSingle = timeData.filter((time) => time.id === id)[0];
        setHours(timeDetailDataSingle.diff);
        setTimeDetailDataId(id);
        setTimeDetailUpdate(true);
        form2.setFieldsValue({
            date:
                (timeDetailDataSingle &&
                    dayjs(timeDetailDataSingle.date, "YYYY-MM-DD")) ||
                "",
            agent: timeDetailDataSingle?.agent ? timeDetailDataSingle.agent.id : "",
            start_end_time: timeDetailDataSingle && [
                dayjs(timeDetailDataSingle?.start_time, "HH:mm"),
                dayjs(timeDetailDataSingle?.end_time, "HH:mm"),
            ],
        });
    };
    const handleCancelUpdateTime = () => {
        form2.resetFields();
        setTimeDetailUpdate(false);
        setHours("");
    };
    const handleUpdateTimeData = (e) => {
        const updateTimeRequest = {
            ticket: parseInt(ticketId),
            agent: e.agent,
            date: dayjs(e.date).format("YYYY-MM-DD"),
            start_time: dayjs(e.start_end_time[0]).format("HH:mm"),
            end_time: dayjs(e.start_end_time[1]).format("HH:mm"),
        };
        actionUpdateTime(updateTimeRequest, timeDetailDataId);
        form2.resetFields();
        setHours("");
        setTimeDetailUpdate(false);
    };

    const columns = [
        {
            title: "Date",
            width: 90,
            // ellipsis: true,
            dataIndex: "date",
            key: "date",
            render: (item) => <p>{dayjs(item).format("DD/MM/YYYY")}</p>,
            // fixed: 'left',
        },
        {
            title: "Agent",
            width: 100,
            // ellipsis: true,
            dataIndex: "agent",
            key: "agent",
            render: (item) => <p>{`${item.first_name} ${item.last_name}`}</p>,
        },
        {
            title: "Start Time",
            dataIndex: "start_time",
            key: "1",
            width: 80,
            // ellipsis: true,
        },
        {
            title: "End Time",
            dataIndex: "end_time",
            key: "2",
            width: 80,
            // ellipsis: true,
        },
        {
            title: "Total Time",
            width: 100,
            dataIndex: "diff",
            key: "totalTime",
            // ellipsis: true,
            // fixed: 'left',
        },
        {
            title: "Action",
            key: "operation",
            fixed: "right",
            width: 60,
            render: (item) => (
                <>
                    <Space size="middle">
                        {/* <Link to={`/edit-ticket/${item.id}`} style={{ color: "black" }}> */}
                        <Tooltip placement="top" title={"Update"}>
                            <EditOutlined
                                style={{
                                    color: "#265BA6",
                                    fontSize: "18px",
                                    // marginRight: 5,
                                }}
                                onClick={() => handleSetTimeData(item.id)}
                            />
                        </Tooltip>
                        {/* </Link> */}
                        <Tooltip placement="top" title={"Delete"}>
                            <Popconfirm
                                okButtonProps={
                                    {
                                        // loading: deleteTimeLoading,
                                    }
                                }
                                placement="top"
                                title="Are you sure you want to delete?"
                                onConfirm={() => confirm({ ticket: parseInt(ticketId) }, item.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteOutlined
                                    style={{
                                        color: "#265BA6",
                                        fontSize: "18px",
                                        marginRight: 10,
                                    }}
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    const handleAddtime = (e) => {
        const addTimeRequest = {
            ticket: parseInt(ticketId),
            agent: e.agent,
            date: dayjs(e.date).format("YYYY-MM-DD"),
            start_time: dayjs(e.start_end_time[0]).format("HH:mm"),
            end_time: dayjs(e.start_end_time[1]).format("HH:mm"),
        };
        actionAddTime(addTimeRequest);
        form2.resetFields();
        setHours("");
    };

    return (
        <Spin spinning={getTimedLoader}>
            <div className='w-full lg:w-[80%] xl:w-[75%] 2xl:w-[65%] mx-auto'>
            <Card className="border-bottom-0 p-4 mt-5">
                <Form
                    layout="vertical"
                    form={form2}
                    onFinish={(e) => {
                        timeDetailUpdate ? handleUpdateTimeData(e) : handleAddtime(e);
                    }}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="agent"
                                className="mb-2 "
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Agent",
                                    },
                                ]}
                            >
                                <Select
                                    className="text-left-select !bg-[#f8f8f8] py-5"
                                    optionFilterProp="children"
                                    showSearch
                                    style={{ Width: 250 }}
                                    allowClear
                                    filterOption={filterOption}
                                    placeholder="Select Agent"
                                    options={agentData}
                                    disabled={
                                        userProfileData && userProfileData.roles !== "ADMIN"
                                    }
                                    defaultValue={
                                        userProfileData && userProfileData.roles === "AGENT"
                                            ? userProfileData.id
                                            : null
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="date"
                                className=" mb-2"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select date!",
                                    },
                                ]}
                            >
                                <DatePicker
                                    className="w-full"
                                    // disabledDate={(current) => current && current < moment().startOf('year')}
                                    // format={"YYYY-MM-DD"}
                                    bordered={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={18} className="px-0 ">
                            <Form.Item
                                name="start_end_time"
                                className="helloRange"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select time!",
                                    },
                                ]}
                            >
                                <TimePicker.RangePicker
                                    showTime={{ format: "HH:mm" }}
                                    bordered={false}
                                    style={{ width: "100%" }}
                                    showNow={true}
                                    use12Hours={false}
                                    format="HH:mm"
                                    onChange={totalHours}
                                />
                            </Form.Item>
                        </Col>

                        <Col align="middle" className='ml-3'>
                            <Form.Item>
                                <Input
                                    disabled
                                    style={{
                                        background: "white",
                                        color: "black",
                                        cursor: "auto",
                                        padding: "3px 0px",
                                        width: "100px",
                                        paddingLeft: 0,
                                        fontSize: "13px",
                                        fontWeight:600,
                                        textAlign: "center",
                                        border: "0.6px solid rgb(231 218 218)",
                                        marginTop: "6px",
                                    }}
                                    placeholder="0 hrs 0 min"
                                    bordered={false}
                                    value={Hours}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} className="h-7 text-end">
                            <Form.Item>
                                {timeDetailUpdate ? (
                                    <Button
                                        className="w-[110px] ml-auto mr-2"
                                        onClick={handleCancelUpdateTime}
                                    >
                                        Cancel
                                    </Button>
                                ) : (
                                    ""
                                )}
                                <Button
                                    htmlType="submit"
                                    className="w-[110px] ml-auto"
                                    loading={addTimeLoader}
                                // disabled={true}
                                >
                                    {timeDetailUpdate ? "Update" : "Save"}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>

            <div className="mt-4 text-lg font-semibold text-[#265ba6] leading-7 tracking-wide text-end">
                Total:{" "}
                {timeCount
                    ? timeCount.toString().split(":")[0] +
                    "hrs " +
                    timeCount.toString().split(":")[1] +
                    "min"
                    : "0hrs 0min"}
            </div>

            <Table
                className="mt-2 mb-5 empty-data"
                pagination={false}
                columns={columns}
                dataSource={timeData}
                scroll={{
                    // x: "max-content",
                    y: 300,
                }}
            />
            </div>
        </Spin>
    )
}

const mapStateToProps = (state) => {
    return {
        allUsersData: state.users.allUsersData,
        getTimedLoader: state.ticket.getTimedLoader,
        timeLoader: state.time.timeLoader,
        timeData: state.time.timeData,
        timeCount: state.time.timeCount,
        addTimeLoader: state.time.addTimeLoader,
        addTimeData: state.time.addTimeData,
        deleteTimeLoading: state.time.deleteTimeLoading,
        timeUpdateLoader: state.time.timeUpdateLoader,
        timeDetailLoader: state.time.timeDetailLoader,
        timeDetailData: state.time.timeDetailData,
        userProfileData: state.auth.userProfileData,
    };
};

export default connect(mapStateToProps, {
    actionGetUsersAllData,
    actionGetAllTimingResolution,
    actionCreateTimingResolution,
    actionGetTimeAll,
    actionAddTime,
    actionDeleteTime,
    actionUpdateTime,
    actionGetTimeDetail
})(ResolutionTime);