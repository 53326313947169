import {
  DashboardOutlined,
  DownOutlined,
  FileTextOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProjectOutlined,
  TagOutlined,
  UserOutlined,
  ContainerOutlined,
  LineChartOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { Col, Dropdown, Image, Layout, Menu, Row, theme } from "antd";
import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/img/comman/logo.png";
import { logoutUser } from "../../store/actions/authAction";
const { Header, Content, Footer, Sider } = Layout;

const MainLayout = (props) => {
  const { logoutUser, userProfileData, activeKey } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const history = useLocation();
  // const history = useHistory();

  const [collapsed, setCollapsed] = useState(false);
  const [keyName, setKeyName] = useState("1");

  useMemo(() => {
    if (
      history.pathname === "/" ||
      history.pathname.startsWith("/change-password")
    ) {
      setKeyName("1");
    } else if (
      history.pathname === "/tickets" ||
      history.pathname.startsWith("/view-ticket/") ||
      history.pathname === "/create-ticket" ||
      history.pathname.startsWith("/edit-resolve") ||
      history.pathname.startsWith("/ticket-view") ||
      history.pathname.startsWith("/edit-ticket/")
    ) {
      setKeyName("2");
    } else if (
      history.pathname === "/users" ||
      history.pathname === "/create-user" ||
      history.pathname === "/create-agent" ||
      history.pathname.startsWith("/update-user") ||
      history.pathname.startsWith("/view-user/")
    ) {
      setKeyName("3");
    } else if (
      history.pathname === "/projects" ||
      history.pathname === "/create-project" ||
      history.pathname.startsWith("/update-project/") ||
      history.pathname.startsWith("/view-project/")
    ) {
      setKeyName("4");
    } else if (
      history.pathname === "/knowledge" ||
      history.pathname === "/create-knowledge" ||
      history.pathname.startsWith("/create-knowledge/") ||
      history.pathname.startsWith("/update-knowlegde/") ||
      history.pathname.startsWith("/view-knowlegde/")
    ) {
      setKeyName("5");
    } else if (
      history.pathname === "/report" ||
      history.pathname === "/user-productivity-report" ||
      history.pathname === "/time-spent-report" ||
      history.pathname === "/agent-yearly-report" ||
      history.pathname === "/actual-hours-report"
    ) {
      setKeyName("6");
    } else if (
      history.pathname === "/ticket-report" ||
      history.pathname === "/ticket-agent-yearly-report" ||
      history.pathname === "/ticket-time-spent-report"
    ) {
      setKeyName("7");
    } else setKeyName("1");
  }, [history]);

  const itemData =
    userProfileData && userProfileData.roles === "ADMIN"
      ? [
          {
            key: "1",
            icon: (
              <DashboardOutlined
                className="opacity-75 hover:text-white"
                style={{
                  fontSize: 20,
                  color:
                    // history.pathname === "/"
                    //   ? // history.pathname === "/edit-profile"
                    //     // history.pathname.startsWith("/change-password/")
                    //     "white"
                    //   : "black",
                    history.pathname === "/" ||
                    history.pathname.startsWith("/change-password")
                      ? "white"
                      : "black",
                }}
              />
            ),
            label: (
              <NavLink
                className="hover:text-white"
                to="/"
                style={{
                  fontWeight: 500,
                  color:
                    history.pathname === "/" ||
                    history.pathname.startsWith("/change-password") ||
                    collapsed
                      ? // history.pathname === "/edit-profile"
                        // history.pathname.startsWith("/change-password/")
                        "white"
                      : "black",
                }}
              >
                Dashboard
              </NavLink>
            ),
          },
          {
            key: "2",
            icon: (
              <TagOutlined
                className="opacity-75 hover:text-white"
                style={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color:
                    history.pathname === "/tickets" ||
                    history.pathname.startsWith("/view-ticket/") ||
                    history.pathname === "/create-ticket" ||
                    history.pathname.startsWith("/edit-resolve") ||
                    history.pathname.startsWith("/ticket-view") ||
                    history.pathname.startsWith("/edit-ticket/")
                      ? "white"
                      : "black",
                }}
              />
            ),
            label: (
              <NavLink
                to="/ticket-view"
                className={`hover:text-white add-class`}
                style={{
                  fontWeight: 500,
                  color:
                    history.pathname === "/tickets" ||
                    history.pathname.startsWith("/view-ticket/") ||
                    history.pathname === "/create-ticket" ||
                    history.pathname.startsWith("/edit-resolve") ||
                    history.pathname.startsWith("/ticket-view") ||
                    history.pathname.startsWith("/edit-ticket/") ||
                    collapsed
                      ? "white"
                      : "black",
                }}
                // to="/tickets"
              >
                Tickets
              </NavLink>
            ),
          },
          {
            key: "3",
            icon: (
              <UserOutlined
                className="opacity-75"
                style={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color:
                    history.pathname === "/users" ||
                    history.pathname === "/create-user" ||
                    history.pathname === "/create-agent" ||
                    history.pathname.startsWith("/update-user/") ||
                    history.pathname.startsWith("/view-user/")
                      ? "white"
                      : "black",
                }}
              />
            ),
            label: (
              <NavLink
                to="/users"
                style={{
                  fontWeight: 500,
                  color:
                    history.pathname === "/users" ||
                    history.pathname === "/create-agent" ||
                    history.pathname === "/create-user" ||
                    history.pathname === "/create-agent" ||
                    history.pathname.startsWith("/update-user/") ||
                    history.pathname.startsWith("/view-user/") ||
                    collapsed
                      ? "white"
                      : "black",
                }}
              >
                Users
              </NavLink>
            ),
          },
          {
            key: "4",
            icon: (
              <ProjectOutlined
                className="opacity-75"
                style={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color:
                    history.pathname === "/projects" ||
                    history.pathname === "/create-project" ||
                    history.pathname.startsWith("/update-project/") ||
                    history.pathname.startsWith("/view-project/")
                      ? "white"
                      : "black",
                }}
              />
            ),
            label: (
              <NavLink
                style={{
                  fontWeight: 500,
                  color:
                    history.pathname === "/projects" ||
                    history.pathname === "/create-project" ||
                    history.pathname.startsWith("/update-project/") ||
                    history.pathname.startsWith("/view-project/") ||
                    collapsed
                      ? "white"
                      : "black",
                }}
                to="/projects"
              >
                Projects
              </NavLink>
            ),
          },
          {
            key: "5",
            icon: (
              <FileTextOutlined
                className="opacity-75"
                style={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color:
                    history.pathname === "/knowledge" ||
                    history.pathname === "/create-knowledge" ||
                    history.pathname.startsWith("/create-knowledge/") ||
                    history.pathname.startsWith("/update-knowlegde/") ||
                    history.pathname.startsWith("/view-knowlegde/")
                      ? "white"
                      : "black",
                }}
              />
            ),
            label: (
              <NavLink
                style={{
                  fontWeight: 500,
                  color:
                    history.pathname === "/knowledge" ||
                    history.pathname === "/create-knowledge" ||
                    history.pathname.startsWith("/create-knowledge/") ||
                    history.pathname.startsWith("/update-knowlegde/") ||
                    history.pathname.startsWith("/view-knowlegde/") ||
                    collapsed
                      ? "white"
                      : "black",
                }}
                to="/knowledge"
              >
                Knowledge Base
              </NavLink>
            ),
          },
          {
            key: "6",
            icon: (
              <LineChartOutlined
                className="opacity-75"
                style={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color:
                    history.pathname === "/report" ||
                    history.pathname === "/user-productivity-report" ||
                    history.pathname === "/time-spent-report" ||
                    history.pathname === "/agent-yearly-report" ||
                    history.pathname === "/actual-hours-report"
                      ? "white"
                      : "black",
                }}
              />
            ),
            label: (
              <NavLink
                to="/report"
                style={{
                  fontWeight: 500,
                  color:
                    history.pathname === "/report" ||
                    history.pathname === "/user-productivity-report" ||
                    history.pathname === "/time-spent-report" ||
                    history.pathname === "/agent-yearly-report" ||
                    history.pathname === "/actual-hours-report" ||
                    collapsed
                      ? "white"
                      : "black",
                }}
              >
                Reports
              </NavLink>
            ),
          },
          {
            key: "7",
            icon: (
              <BarChartOutlined
                className="opacity-75"
                style={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color:
                    history.pathname === "/ticket-report" ||
                    history.pathname === "/ticket-agent-yearly-report" ||
                    history.pathname === "/ticket-time-spent-report"
                      ? "white"
                      : "black",
                }}
              />
            ),
            label: (
              <NavLink
                to="/ticket-report"
                style={{
                  fontWeight: 500,
                  color:
                    history.pathname === "/ticket-report" ||
                    history.pathname === "/ticket-agent-yearly-report" ||
                    history.pathname === "/ticket-time-spent-report" ||
                    collapsed
                      ? // collapsed
                        "white"
                      : "black",
                }}
              >
                Ticket Reports
              </NavLink>
            ),
          },
        ]
      : userProfileData && userProfileData.roles === "AGENT"
      ? [
          {
            key: "1",
            icon: (
              <DashboardOutlined
                className="opacity-75"
                style={{
                  fontSize: 20,
                  color:
                    history.pathname === "/"
                      ? // history.pathname === "/edit-profile" ||
                        // history.pathname === "/change-password/"
                        "white"
                      : "black",
                }}
              />
            ),
            label: (
              <NavLink
                to="/"
                style={{
                  fontWeight: 500,
                  color:
                    history.pathname === "/" || collapsed
                      ? // history.pathname === "/edit-profile" ||
                        // history.pathname === "/change-password/"
                        "white"
                      : "black",
                }}
              >
                Dashboard
              </NavLink>
            ),
          },
          {
            key: "2",
            icon: (
              <TagOutlined
                className="opacity-75"
                style={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color:
                    history.pathname === "/tickets" ||
                    history.pathname === "/create-ticket" ||
                    history.pathname.startsWith("/view-ticket/") ||
                    history.pathname === "/create-ticket" ||
                    history.pathname.startsWith("/edit-resolve") ||
                    history.pathname.startsWith("/edit-ticket/")
                      ? "white"
                      : "black",
                }}
              />
            ),
            label: (
              <NavLink
                to="/ticket-view"
                className="text-black"
                style={{
                  fontWeight: 500,
                  color:
                    history.pathname === "/tickets" ||
                    history.pathname.startsWith("/view-ticket/") ||
                    history.pathname === "/create-ticket" ||
                    history.pathname.startsWith("/edit-resolve") ||
                    history.pathname.startsWith("/edit-ticket/") ||
                    collapsed
                      ? "white"
                      : "black",
                }}
              >
                Tickets
              </NavLink>
            ),
          },
          {
            key: "5",
            icon: (
              <FileTextOutlined
                className="opacity-75"
                style={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color:
                    history.pathname === "/knowledge" ||
                    history.pathname === "/create-knowledge" ||
                    history.pathname.startsWith("/create-knowledge/") ||
                    history.pathname.startsWith("/update-knowlegde/") ||
                    history.pathname.startsWith("/view-knowlegde/")
                      ? "white"
                      : "black",
                }}
              />
            ),
            label: (
              <NavLink
                style={{
                  fontWeight: 500,
                  color:
                    history.pathname === "/knowledge" ||
                    history.pathname === "/create-knowledge" ||
                    history.pathname.startsWith("/create-knowledge/") ||
                    history.pathname.startsWith("/update-knowlegde/") ||
                    history.pathname.startsWith("/view-knowlegde/") ||
                    collapsed
                      ? "white"
                      : "black",
                }}
                to="/knowledge"
              >
                Knowledge Base
              </NavLink>
            ),
          },
        ]
      : [
          {
            key: "1",
            icon: (
              <DashboardOutlined
                className="opacity-75"
                style={{
                  fontSize: 20,
                  color:
                    history.pathname === "/"
                      ? // history.pathname === "/edit-profile" ||
                        // history.pathname === "/change-password/"
                        "white"
                      : "black",
                }}
              />
            ),
            label: (
              <NavLink
                to="/"
                style={{
                  fontWeight: 500,
                  color:
                    history.pathname === "/" || collapsed
                      ? // history.pathname === "/edit-profile" ||
                        // history.pathname === "/change-password/"
                        "white"
                      : "black",
                }}
              >
                Dashboard
              </NavLink>
            ),
          },
          {
            key: "2",
            icon: (
              <TagOutlined
                className="opacity-75"
                style={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color:
                    history.pathname === "/tickets" ||
                    history.pathname === "/create-ticket" ||
                    history.pathname.startsWith("/view-ticket/") ||
                    history.pathname === "/create-ticket" ||
                    history.pathname.startsWith("/edit-resolve") ||
                    history.pathname.startsWith("/edit-ticket/")
                      ? "white"
                      : "black",
                }}
              />
            ),
            label: (
              <NavLink
                to="/ticket-view"
                className="text-black"
                style={{
                  fontWeight: 500,
                  color:
                    history.pathname === "/tickets" ||
                    history.pathname.startsWith("/view-ticket/") ||
                    history.pathname === "/create-ticket" ||
                    history.pathname.startsWith("/edit-resolve") ||
                    history.pathname.startsWith("/edit-ticket/") ||
                    collapsed
                      ? "white"
                      : "black",
                }}
              >
                Tickets
              </NavLink>
            ),
          },
          userProfileData.roles === "BUSINESS USER" &&
            userProfileData.is_reports_visible === "YES" && {
              key: "7",
              icon: (
                <BarChartOutlined
                  className="opacity-75"
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color:
                      history.pathname === "/ticket-report" ||
                      history.pathname === "/ticket-agent-yearly-report" ||
                      history.pathname === "/ticket-time-spent-report"
                        ? "white"
                        : "black",
                  }}
                />
              ),
              label: (
                <NavLink
                  to="/ticket-report"
                  style={{
                    fontWeight: 500,
                    color:
                      history.pathname === "/ticket-report" ||
                      history.pathname === "/ticket-agent-yearly-report" ||
                      history.pathname === "/ticket-time-spent-report" ||
                      collapsed
                        ? // collapsed
                          "white"
                        : "black",
                  }}
                >
                  Ticket Reports
                </NavLink>
              ),
            },
        ];

  const logoutBtn = () => {
    setKeyName("1");
    logoutUser(navigate);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout id="main-layout">
      <Sider
        style={{
          background: colorBgContainer,
          display: `${
            history.pathname === "/login" ||
            history.pathname === "/forgot-password" ||
            history.pathname === "/reset-password" ||
            history.pathname === "/reset-password/" ||
            history.pathname === "/ticket-view" ||
            history.pathname.startsWith("/ticket-view/")
              ? "none"
              : ""
          }`,
        }}
        //=className="sidebar"
        breakpoint="xxl"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={(collapsed, type) => {
          setCollapsed(collapsed);
        }}
      >
        <div className="logo text-center cursor-pointer pt-2">
          <Image preview={false} src={logo} />
        </div>

        <Menu
          id="overflow-menu"
          theme="light"
          mode="inline"
          className="mt-8"
          selectedKeys={[keyName]}
          items={itemData}
        />
      </Sider>
      <Layout>
        <Header
          className="site-layout-sub-header-background"
          style={{
            padding: 0,
            display: `${
              history.pathname === "/login" ||
              history.pathname === "/forgot-password" ||
              history.pathname === "/ticket-view" ||
              history.pathname.startsWith("/ticket-view/")
                ? "none"
                : ""
            }`,
          }}
        >
          {history.pathname === "/login" ||
          history.pathname === "/forgot-password" ||
          history.pathname === "/reset-password/" ||
          history.pathname === "/reset-password" ? (
            ""
          ) : (
            <Row align="middle">
              <Col span={3} align="left">
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger  custom-tooltip`",
                    onClick: () => setCollapsed(!collapsed),
                  }
                )}
              </Col>
              <Col
                span={21}
                align="right"
                justify="center"
                className="justify-center flex flex-col"
              >
                <Row
                  justify={"end"}
                  align="middle"
                  className="flex custom-drop"
                >
                  <Col
                    xxl={4}
                    md={10}
                    sm={10}
                    lg={5}
                    align="middle"
                    className="flex justify-end align-center leading-none"
                  >
                    <Dropdown
                      trigger="hover"
                      menu={{
                        items: [
                          {
                            label: (
                              <div>
                                <NavLink
                                  style={{ color: "black", fontWeight: 400 }}
                                  to={`/edit-profile`}
                                  //onClick={changePassword}
                                >
                                  Edit profile
                                </NavLink>
                              </div>
                            ),
                          },
                          {
                            label: (
                              <div>
                                <NavLink
                                  style={{ color: "black", fontWeight: 400 }}
                                  to={`/change-password/${userProfileData.id}`}
                                  //onClick={changePassword}
                                >
                                  Change Password
                                </NavLink>
                              </div>
                            ),
                          },
                          {
                            label: (
                              <div>
                                <p
                                  style={{ color: "black" }}
                                  onClick={logoutBtn}
                                >
                                  Logout
                                </p>
                              </div>
                            ),
                          },
                        ],
                      }}
                    >
                      <div
                        onClick={(e) => e.preventDefault()}
                        className="cursor-pointer"
                      >
                        {userProfileData &&
                          userProfileData.first_name &&
                          userProfileData.last_name && (
                            <p className="text-20 text-primary text-xl font-bold flex items-center ">
                              {`${userProfileData.first_name} ${userProfileData.last_name} `}
                              <DownOutlined
                                style={{ fontSize: 15, marginLeft: 5 }}
                              />
                            </p>
                          )}
                      </div>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Header>
        <Content
          style={{
            margin: "24px 16px 0",
            height: `${
              history.pathname === "/login" ||
              history.pathname === "/forgot-password" ||
              history.pathname === "/reset-password" ||
              history.pathname === "/reset-password/" ||
              history.pathname.startsWith("/ticket-view/") ||
              history.pathname === "/ticket-view"
                ? "100vh"
                : " calc(100vh - 80px)"
            }`,
            overflowY: "auto",
          }}
        >
          <div
            id={
              history.pathname === "/login" ||
              history.pathname === "/forgot-password" ||
              history.pathname === "/reset-password" ||
              history.pathname === "/reset-password/" ||
              history.pathname === "/ticket-view"
                ? "content-full"
                : ""
            }
            className={
              history.pathname.startsWith("/view-ticket/")
                ? "site-layout-background cutsom-full"
                : "site-layout-background"
            }
            // className={`site-layout-background}
            style={{
              padding: 24,
              // paddingTop:0,
              minHeight: 360,
              display: history.pathname === "/ticket-view" && "block",
              height: history.pathname === "/ticket-view" ? "auto" : "auto",
            }}
          >
            {props.children}
          </div>
        </Content>
        {/* <Footer
        style={{
          textAlign: "center",
        }}
      >
        ©2022 Created by Vytech Enterpise
      </Footer> */}
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
  };
};
export default connect(mapStateToProps, {
  logoutUser,
})(MainLayout);
