import { HomeOutlined, LeftCircleFilled, ArrowLeftOutlined, ArrowRightOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Tabs, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Task1 from "../Task/Task1";
import ViewTicket from "./ViewTicket";
import Task2 from "../Task/Task2";
import Task from "../Task/Task";
import { useNavigate, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { actionGetAllTask, actionGetTicketDetail } from "../../store/actions/ticketAction";
import ResolutionTime from "./ResolutionTime";
import { actionGetTimeAll } from "../../store/actions/timeAction";

const { TabPane } = Tabs;

const Accordion = (props) => {
  const { userProfileData } = props
  const [IsSticky, setIsSticky] = useState(false);

  const ticketId = useParams()
  const urlParams = new URLSearchParams(window.location.search);
  const keyParam = urlParams.get('keyword');
  const keyword = urlParams.get("keyword") || "";
  const paramValue = urlParams.get("status") || "";
  const rateIssue = urlParams.get("rate_issue") || "";
  const srSeverity = urlParams.get("sr_severity") || "";
  const levelParams = urlParams.get("level") || "";
  const sDate = urlParams.get("start_date") || "";
  const eDate = urlParams.get("end_date") || "";
  const Navigate = useNavigate();
  const storedUrl = localStorage.getItem("ticketTableUrl");

  const {
    actionGetTicketDetail,
    ticketsDetailData,
    actionGetAllTask,
    actionGetTimeAll
  } = props;

  useEffect(() => {
    handleGetTcketDetailsData(ticketId.ticketId);
  }, [ticketId.ticketId])

  const items = [
    {
      key: "1",
      label: "View Ticket",
      children: <ViewTicket />,
    },
    {
      key: "2",
      label: "Task List",
      children: <Task2 />,
    },
    (userProfileData.roles !== "AGENT" &&
    {
      key: "3",
      label: "Resolution Time",
      children: <ResolutionTime />,
    }),
  ];
  const onChange = (e) => {
    //actionGetTicketDetail(ticketId.ticketId);
  };

  const handleGetTcketDetailsData = (id) => {
    // actionGetTicketDetail(ticketId.ticketId);
    // Build query string dynamically
    const queryParams = [];
    if (keyword) queryParams.push(`keyword=${encodeURIComponent(keyword)}`);
    if (paramValue) queryParams.push(`status=${encodeURIComponent(paramValue)}`);
    if (rateIssue) queryParams.push(`rate_issue=${encodeURIComponent(rateIssue)}`);
    if (srSeverity) queryParams.push(`sr_severity=${encodeURIComponent(srSeverity)}`);
    if (levelParams) queryParams.push(`level=${encodeURIComponent(levelParams)}`);
    if (sDate) queryParams.push(`start_date=${encodeURIComponent(sDate)}`);
    if (eDate) queryParams.push(`end_date=${encodeURIComponent(eDate)}`);

    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

    // Navigate to the updated URL
    Navigate(
      `/view-ticket/${id}${queryString}`
    );
    actionGetTicketDetail(
      id,
      keyword,
      paramValue,
      rateIssue,
      srSeverity,
      levelParams,
      sDate,
      eDate,
    );
    actionGetAllTask(id);
    actionGetTimeAll(id)
  };

  const breadcrumbItems = [
    {
      key: 'back',
      title: (
        <>
          <ArrowLeftOutlined style={{ cursor: 'pointer' }} />
          <span style={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.88)', fontWeight: '600', fontSize: 18 }}>Go Back</span>
        </>
      ),
      onClick: () => Navigate(storedUrl),
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center sticky top-0 z-10 bg-[#f1f5f9]">
        <Breadcrumb items={breadcrumbItems} />

        <div className="flex items-center space-x-2">
          <Tooltip title="Previous Ticket">
            <div
              onClick={() => ticketsDetailData?.prev_record?.ticket_id && handleGetTcketDetailsData(ticketsDetailData?.prev_record?.ticket_id)}
              className={`border border-[#265ba6] h-[30px] w-[30px] flex items-center justify-center rounded-md shadow ${ticketsDetailData?.prev_record?.ticket_id
                ? "bg-white cursor-pointer"
                : "bg-gray-200 cursor-not-allowed opacity-50"
                }`}
            >
              <LeftOutlined className="text-lg text-[#265ba6] font-bold" />
            </div>
          </Tooltip>
          <Tooltip title="Next Ticket">
            <div
              onClick={() => ticketsDetailData?.next_record?.ticket_id && handleGetTcketDetailsData(ticketsDetailData?.next_record?.ticket_id)}
              className={`border border-[#265ba6] h-[30px] w-[30px] flex items-center justify-center rounded-md shadow ${ticketsDetailData?.next_record?.ticket_id
                ? "bg-white cursor-pointer"
                : "bg-gray-200 cursor-not-allowed opacity-50"
                }`}
            >
              <RightOutlined className="text-lg text-[#265ba6]" />
            </div>
          </Tooltip>
        </div>
      </div>
      <Tabs
        defaultActiveKey={`${keyParam ? 2 : 1}`}
        items={items}
        onChange={onChange}
        onTabClick={(key) => {
          key === '1' && handleGetTcketDetailsData(ticketId.ticketId);
        }}
      /></>
  )
};

const mapStateToProps = (state) => {
  return {
    ticketsDetailData: state.ticket.ticketsDetailData,
    userProfileData: state.auth.userProfileData,

  };
};
export default connect(mapStateToProps, {
  actionGetTicketDetail,
  actionGetAllTask,
  actionGetTimeAll
})(Accordion);